<script>
export default {
	props: {
		tag: {
			type: String,
			default: 'div'
		},
		isKmh: {
			type: Boolean,
			default: false
		},
		direction: {
			type: Number,
			default: null
		}
	},
	data: () => ({
		speed: 28
	}),
	computed: {
		renderedContent() {
			const speedInKMH = this.isKmh ? this.speed : this.speed * 3.6;

			return `${Math.round(speedInKMH)}km/h`;
		}
	},
	created() {
		this.speed = this.$slots.default[0].text;
	},
	render(h) {
		this.speed = this.$slots.default[0].text;
		let icon = null;
		if (this.direction) {
			icon = h(
				'ws-icon',
				{
					props: {
						color: 'grey'
					},
					style: {
						transform: `rotate(${this.direction + 180}deg)`,
						lineHeight: 0.2,
						display: 'block'
					}
				},
				'wind-direction'
			);
		}
		return h(this.tag, { class: 'ws-wind grey--text' }, [
			icon,
			h('small', {}, this.renderedContent)
		]);
	}
};
</script>

<style lang="sass">
.ws-wind
	small
		font-size: 9px !important
</style>
