<script>
export default {
	props: {
		tag: {
			type: String,
			default: 'div'
		},
		showBold: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		temperature: 28
	}),
	computed: {
		renderedContent() {
			return `${this.temperature}°`;
		},
		classname() {
			return `cl-temp cl-temp--${this.temperature} ${
				this.showBold ? 'font-weight-bold' : ''
			}`;
		}
	},
	created() {
		this.temperature = this.$slots.default[0].text;
	},
	render(h) {
		this.temperature = this.$slots.default[0].text;
		return h(this.tag, {
			class: this.classname,
			domProps: {
				innerHTML: this.renderedContent
			}
		});
	}
};
</script>

<style lang="scss">
.cl-temp {
	&:hover {
		color: black !important;
	}
	@each $temp, $color in $temp-colors {
		&--#{$temp} {
			color: $color !important;
		}
	}
	&--0 {
		color: map-get($temp-colors, '-1') !important;
	}
	&--1,
	&--2,
	&--3,
	&--4,
	&--5 {
		color: map-get($temp-colors, '6') !important;
	}
}
</style>
