<template lang="pug">
div
	.text-right
		a(v-if="days !== 6" @click="days=6") {{$t('page.areas._area.forecast:forecast_table.toggle_6days')}}
		a(v-if="days !== 10" @click="days=10") {{$t('page.areas._area.forecast:forecast_table.toggle_10days')}}
	.forecast-table-wrapper
		table.forecast-table.mb-6(ref="table")
			colgroup
				col(width="100px")
				col( v-for="forecast in forecastDays" :style="`background-color:${[0,6].includes($dayjs(forecast.realdate).day())? '#fafafa' : '#fff'}`"  span="8")
			thead
				tr
					td
					th(colspan="8" v-for="forecast in forecastDays") {{$dayjs(forecast.realdate).format('dd')}}
			tbody
				tr(v-if="days === 6")
					th
					td.caption(:colspan="2" v-for="forecast in forecastActive") {{$dayjs(forecast.realdate).format(timeformat)}}
				tr
					th
					td(:colspan="colspan" v-for="forecast in forecastActive")
						img(:src="`https://static.wintersport.nl/images/weather/${forecast.icon}.svg`" width="16px")
				tr
					th {{$t('page.areas._area.forecast:forecast_table.mountain')}}
						span.font-weight-regular.grey--text.d-block {{area.maxElevation}}m
					display-temp(tag="td" :colspan="colspan" v-for="forecast in forecastActive" :key="`temp-${forecast.hour}`") {{forecast.temperature}}
				tr(v-if="activeresort")
					th(v-if="area.resorts.nodes.length === 1") {{activeresort.name}}
						span.font-weight-regular.grey--text.d-block {{activeresort.elevation}}m
					th(v-else)
						v-menu(offset-y)
							template(#activator="{on}")
								span(v-on="on" style="cursor: pointer;white-space: nowrap") {{activeresort.name}}
									ws-icon(small) caret-down
							v-list(dense)
								v-list-item(v-for="(resort, index) in area.resorts.nodes" :key="index" @click="activeResortIndex = index")
									v-list-item-title {{resort.name}} ({{resort.elevation}}m)
						span.font-weight-regular.grey--text.d-block {{activeresort.elevation}}m
					display-temp(tag="td" :colspan="colspan" v-for="forecast in forecastActiveResort" :key="`temp-${activeresort.id}-${forecast.hour}`") {{forecast.temperature}}
				tr.bottom-border
					th {{$t('page.areas._area.forecast:forecast_table.wind')}}
					display-wind(tag="td" :colspan="colspan" v-for="forecast in forecastActive" :key="`wind-${forecast.hour}`" :direction="forecast.windDirection") {{forecast.windSpeed}}
				tr.bottom-border.compact
					th {{$t('page.areas._area.forecast:forecast_table.zeroline')}}
					td(v-for="(forecast, index) in zeroline")
						zeroline( v-if="index==0" :data="zeroline" :width="tableContentWidth"  :mountain="area.maxElevation" :valley="(activeresort) ? activeresort.elevation : 0" :min="minGraphElevation" )
				tr.bottom-border
					th
						span {{$t('page.areas._area.forecast:forecast_table.snowfall')}} <br>
						a(@click="showResortbars = false" :class="{'font-weight-regular': showResortbars}") {{$t('page.areas._area.forecast:forecast_table.mountain')}} <br>
						a(@click="showResortbars = true" :class="{'font-weight-regular': !showResortbars}" v-if="activeresort") {{activeresort.name}}
					td.bar-container.bg-lightblue(:colspan="colspan" v-for="forecast in barforecasts" style="height:100px" :key="`bars${forecast.hour}${blocksPerDay}`")
						v-sheet.bar(tile color="#ea8b8b" :style="`height: ${forecast.rain / maxbar * 90}%;`" :snow="forecast.snow" :maxbar="maxbar")
						v-sheet.snow.bar(tile color="accent" :style="`height: ${forecast.snow / maxbar * 90}%;`" :snow="forecast.snow" :maxbar="maxbar")
						.bar.bg-ws-blue(:style="`height: ${forecast.snow / maxbar * 90}%;`")
				tr.bottom-border.compact
					th {{$t('page.areas._area.forecast:forecast_table.snow')}}
					td(:colspan="colspan" v-for="forecast in barforecasts")
						v-chip.px-1.white--text(color="accent" label x-small v-if="forecast.snow > 0") {{Math.round(forecast.snow)}}cm
						small.grey--text(v-else) -
				tr.bottom-border.compact
					th {{$t('page.areas._area.forecast:forecast_table.rain')}}
					td(:colspan="colspan" v-for="forecast in barforecasts")
						v-chip.px-1.white--text(color="#ea8b8b" label x-small v-if="forecast.rain > 0") {{Math.round(forecast.rain)}}mm
						small.px-1.my-1.grey--text(v-else) -
				tr.bottom-border.compact(v-if="days<= 6")
					th
					td(colspan="8" v-for="forecast in forecastDays")
						nuxt-link(:to="localePath({name: 'areas-area-forecast', hash: `#snowfall-${parseInt(forecast.hour)}`, params: { area: areaslug }})")
							region-map(:hour="forecast.hour" mini type="snowfall" :weatherregion="area.denglMeasurepoints[0].forecastRegion" :dot="area.geography.location.coordinates")

				//- tr.bottom-border
					th {{$t('page.areas._area.forecast:forecast_table.sunscore')}}
					td(colspan="8" v-for="forecast in forecastDays" :class="`sunscore-${forecast.sunScore}`")
						ws-icon(small) day-sunny
						span.body-1 {{forecast.sunScore}}
</template>

<script>
import gql from 'graphql-tag';

import DisplayTemp from '@/components/display/temperature.vue';
import DisplayWind from '@/components/display/wind.vue';
import RegionMap from '@/components/weather/weathermap/regionmap.vue';
import Zeroline from '@/components/display/zeroline.vue';

const forecastQuery = gql`
	query forecast($id: UUID!) {
		forecast(id: $id) {
			forecast3 {
				hour
				zeroline
			}
			forecast6 {
				hour
				realdate
				rain
				snow
				temperature
				windDirection
				windSpeed
				icon
			}
			forecast24 {
				hour
				realdate
				rain
				snow
				temperature
				icon
				windDirection
				windSpeed
				sunScore
			}
		}
	}
`;

export default {
	components: {
		Zeroline,
		DisplayTemp,
		DisplayWind,
		RegionMap
	},
	props: {
		areaslug: {
			type: String,
			required: true
		},
		initialActiveResortId: {
			type: [String, Boolean],
			default: false
		},
		calculateInfosheetWidth: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			days: 6,
			blocksPerDay: 4,
			area: {},
			activeResortIndex: 0,
			showResortbars: false,
			forecast24: [],
			forecast6: [],
			forecast3: [],
			forecastResort: {
				forecast24: [],
				forecast6: [],
				forecast3: []
			}
		};
	},
	async fetch() {
		await this.$graphClient
			.query({
				query: gql`
					query area($slug: String!) {
						area(slug: $slug) {
							id
							maxElevation
							denglMeasurepoints {
								id
								forecastRegion
							}
							geography {
								location {
									type
									coordinates
								}
							}
							resorts {
								nodes {
									id
									name
									slug
									elevation
									denglMeasurepoints {
										id
										forecastRegion
									}
								}
							}
						}
					}
				`,
				variables: {
					slug: this.areaslug
				}
			})
			.then(({ data }) => {
				this.area = data.area;

				if (this.initialActiveResortId) {
					this.activeResortIndex =
						this.area.resorts.nodes.findIndex(resort => {
							return resort.id === this.initialActiveResortId;
						}) || 0;
				}

				return this.$graphClient
					.query({
						query: forecastQuery,
						variables: {
							id: data.area.denglMeasurepoints[0].id
						}
					})
					.then(({ data }) => {
						this.forecast24 = data.forecast.forecast24.slice(0, 10);
						this.forecast6 = data.forecast.forecast6.slice(0, 24);
						this.forecast3 = data.forecast.forecast3;
					});
			});
	},
	computed: {
		colspan() {
			return this.days === 6 ? 2 : 8;
		},
		tableContentWidth() {
			if (this.$vuetify.breakpoint.smAndDown) {
				return 860;
			}

			if (this.calculateInfosheetWidth) {
				return this.$store.state.content_width.infosheet - 100 - 48;
			}

			return this.$store.state.content_width.full - 100 - 48;
		},
		maxbar() {
			return [
				...this.forecastResort.forecast6,
				...this.forecast6,
				...this.forecast24,
				this.forecastResort.forecast24
			].reduce((carry, resort) => {
				const max = Math.max(
					carry,
					parseFloat(resort.snow),
					parseFloat(resort.rain)
				);

				if (!isNaN(max)) {
					return max;
				}

				return carry;
			}, 0);
		},
		timeformat() {
			return this.tableContentWidth > 0 &&
				this.tableContentWidth / this.days / this.blocksPerDay < 35
				? 'HH'
				: 'HH:mm';
		},
		activeresort() {
			return this.area.resorts
				? this.area.resorts.nodes[this.activeResortIndex]
				: null;
		},
		minGraphElevation() {
			return this.area.resorts.nodes.reduce((carry, resort) => {
				return Math.min(carry, resort.elevation);
			}, 5000);
		},
		barforecasts() {
			if (this.showResortbars) {
				return this.days === 6
					? this.forecastResort.forecast6
					: this.forecastResort.forecast24;
			}
			return this.days === 6 ? this.forecast6 : this.forecast24;
		},
		forecastDays() {
			return this.forecast24.slice(0, this.days);
		},
		forecastActive() {
			return this.days === 6 ? this.forecast6 : this.forecast24;
		},
		forecastActiveResort() {
			return this.days === 6
				? this.forecastResort.forecast6
				: this.forecastResort.forecast24;
		},
		zeroline() {
			return this.days === 6
				? this.forecast3.slice(0, 48)
				: this.forecast3.slice(0, 80);
		}
	},
	watch: {
		activeresort() {
			this.fetchResortForecast();
		}
	},
	methods: {
		fetchResortForecast() {
			return this.$graphClient
				.query({
					query: forecastQuery,
					variables: {
						id: this.activeresort.denglMeasurepoints[0].id
					}
				})
				.then(({ data }) => {
					this.forecastResort.forecast24 = data.forecast.forecast24.slice(
						0,
						10
					);
					this.forecastResort.forecast6 = data.forecast.forecast6.slice(0, 24);
					this.forecastResort.forecast3 = data.forecast.forecast3;
				});
		}
	}
};
</script>

<style lang="sass">
.forecast-table-wrapper
	overflow-x: hidden
	@media #{map-get($display-breakpoints, 'sm-and-down')}
		overflow-x: auto
	.forecast-table
		border-collapse: collapse
		table-layout: fixed
		font-size: 90%
		width: 100%
		@media #{map-get($display-breakpoints, 'sm-and-down')}
			width: 960px
	.bar-container
		position: relative

		.bar
			display: block
			left: 30%
			right: 25%
			transition: height 0.5s
			bottom: 0
			position: absolute

	.snow &
		left: 25%
		right: 30%

	.bottom-border
		td
			border-bottom: 1px solid var(--v-border-base)

	.compact
		td,
		th
			padding: 0

	td, th
		padding: 0.4rem 0
		vertical-align: top
		border-right: 1px solid var(--v-border-base)
		text-align: center

	th
		padding-left: 0

	tbody
		th
			font-size: 80%
			text-align: left

	thead
		th
			font-weight: bold
			border: 1px solid var(--v-border-base)

@each $rating, $color, $opacity in $sunscore-colors
	.sunscore-#{$rating}
		background-color: $color
		i
			opacity: $opacity
</style>
