
<template lang="pug">
	svg(:height="height" :width="width")
		g.tick(v-for="tick in ticks" :transform="`translate(0,${y(tick)})`")
			line(:x2="width" :x1="margin.left" y2='0')
			text(dy='-6' :x="width - 10"  y='0' style='text-anchor: end;') {{tick}}m
		path.line(:d="zeroline_poly" )
		g(v-if="mountain" :transform="`translate(${margin.left} ${y(mountain)})`")
			line(:x2="width" stroke="#000000")
			rect(width='48' height='12' x='12' y='-6' rx='6' ry='6' fill='#999999')
			text(width='48' height='12' x='36' y='4' fill='#ffffff' font-size='10px' text-anchor='middle') {{mountain}}m
		g(v-if="valley" :transform="`translate(${margin.left} ${y(valley)})`")
			line(:x2="width" stroke="#000000")
			rect(width='48' height='12' x='12' y='-6' rx='6' ry='6' fill='#999999')
			text(width='48' height='12' x='36' y='4' fill='#ffffff' font-size='10px' text-anchor='middle') {{valley}}m
</template>



<style lang="sass">
.line
	stroke: #4aa3df
	stroke-width: 2
	fill: none

svg line
	shape-rendering: crispEdges

svg .tick
	font-size: 10px

svg .tick	line
	fill: none
	stroke: #ddd
	stroke-dasharray: 3,3
</style>
<script>
import { extent } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { line, curveMonotoneX } from 'd3-shape';

export default {
	props: {
		data: {
			type: Array,
			required: true
		},
		mountain: {
			type: Number,
			required: true
		},
		valley: {
			type: Number,
			required: true
		},
		min: {
			type: Number,
			required: true
		},
		width: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			margin: { top: 10, right: 0, bottom: 10, left: 0 },
			height: 150
		};
	},

	computed: {
		bounds() {
			const bounds = [
				this.valley,
				this.mountain,
				this.min,
				...this.data.map(e => e.zeroline)
			];
			return [Math.min(...bounds) * 0.9, Math.max(...bounds) * 1.1];
		},
		y() {
			return scaleLinear()
				.range([this.height - this.margin.bottom, this.margin.top])
				.domain(this.bounds);
		},
		x() {
			return scaleLinear()
				.range([this.margin.left, this.width - this.margin.right])
				.domain(extent(this.data.map(e => e.hour)));
		},
		line() {
			return line()
				.x(d => {
					return this.x(d.hour);
				}) // set the x values for the line generator
				.y(d => {
					return this.y(d.zeroline);
				}) // set the y values for the line generator
				.curve(curveMonotoneX);
		},

		zeroline_poly() {
			return this.line(this.data);
		},
		ticks() {
			return this.y.ticks(4);
		}
	}
};
</script>

